import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Comments`}</h1>
    <ul>
      <li parentName="ul">{`The entire set of <140 character plain text statements (each a `}<a parentName="li" {...{
          "href": "/comment",
          "title": "comment"
        }}>{`comment`}</a>{`) submitted to a single `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{``}</li>
      <li parentName="ul">{`Or, occasionally, the set of `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` submitted to a single `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` by a single `}<a parentName="li" {...{
          "href": "/participant",
          "title": "participant"
        }}>{`participant`}</a>{``}</li>
      <li parentName="ul">{`Because it is total, `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` includes those `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` which the `}<a parentName="li" {...{
          "href": "/Moderation",
          "title": "👹 Moderation"
        }}>{`👹 Moderation`}</a>{` process has approved, and has rejected`}</li>
      <li parentName="ul">{`Sometimes `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` is used as shorthand for 'those `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` on which `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` will `}<a parentName="li" {...{
          "href": "/vote",
          "title": "vote"
        }}>{`vote`}</a>{`', meaning those which have been approved by the `}<a parentName="li" {...{
          "href": "/Moderation",
          "title": "👹 Moderation"
        }}>{`👹 Moderation`}</a>{` process`}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/comment-routing",
          "title": "comment routing"
        }}>{`comment routing`}</a>{`, for instance, operates on all `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` which have been moderated into the `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      